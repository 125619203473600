/**
 * This route is dynamically added to the app if this section is
 * enabled inside the config for the tenant being built.
 */
// @ngInject
function states($stateProvider, nsUtilProvider) {
  $stateProvider
    .state('help', {
      parent: 'root',
      redirectTo: '.contact',
      url: '',
      template: '<ui-view/>',
      resolve: {
        module: /* @ngInject */ function ($ocLazyLoad) {
          return import(
            /* webpackChunkName: "acp.apps.contact-us" */ 'apps/contact-us'
          ).then($ocLazyLoad.loadModule);
        }
      }
    })
    .state('help.contact', {
      url: '/contact',
      templateUrl: 'apps/contact-us/templates/acp-contact.ng.html',
      resolve: {
        pageTitle: getContactSectionPageTitle,
        module: getContactSectionModule
      }
    })
    .state('public.help', {
      url: '',
      redirectTo: '.contact',
      template: '<ui-view/>',
      resolve: {
        module: getContactSectionModule
      }
    })
    .state('public.help.contact', {
      url: '/precontact',
      templateUrl: 'apps/contact-us/templates/acp-unauth-contact.ng.html',
      data: {
        permissions: {
          except: 'isContactMfeEligible',
          redirectTo: 'contact'
        }
      },
      resolve: {
        $hideAppBarLogo: /* @ngInject */ function (acpFrontendConfigModel) {
          return acpFrontendConfigModel.get(
            acpFrontendConfigModel.keys.HIDE_APPBAR_LOGO,
            false
          );
        },
        pageTitle: getContactSectionPageTitle,
        pageH1: /* @ngInject */ function (module, $injector) {
          return $injector.invoke(
            nsUtilProvider.getPageTitle('apps/contact-us', 'page-h1')
          );
        },
        pageMetaDescription: /* @ngInject */ function (module, $injector) {
          return $injector.invoke(
            nsUtilProvider.getPageTitle('apps/contact-us', 'meta-description')
          );
        }
      }
    });

  /* @ngInject */ function getContactSectionPageTitle(module, $injector) {
    return $injector.invoke(nsUtilProvider.getPageTitle('apps/contact-us'));
  }

  /* @ngInject */ function getContactSectionModule($ocLazyLoad) {
    return import(
      /* webpackChunkName: "acp.apps.contact-us" */ 'apps/contact-us'
    ).then($ocLazyLoad.loadModule);
  }
}

export default states;
